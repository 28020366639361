/* eslint-disable import/no-default-export */
import * as React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Layout } from '../components/Layout/Layout'
import {
  ContactContainer,
  ContactImage,
  ContactList,
  ContactNav,
  ItalicText,
} from '../components/elements/contact'
import { ExternalYellow } from '../components/elements/links/ExternalYellow'

const keywords = 'carina martins, contacts'
const description = 'carina martins contacts'

const ContactPage = ({ location }: { location: Location }): JSX.Element => {
  const image = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      file(relativePath: { eq: "carina-martins-contact.jpg" }) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout
      title={'Contact'}
      lang="en"
      alternate="/pt/contacto"
      description={description}
      keywords={keywords}
      location={location}
    >
      <ContactContainer>
        <ContactList>
          <p>
            <ItalicText>Get in touch </ItalicText>
            <br />
            <ExternalYellow linkTo="mailto:carinamrts@gmail.com?subject=Email from carinamartins.com">
              carinamrts[at]gmail.com
            </ExternalYellow>
          </p>
          <ContactNav>
            <ul>
              <p>
                <ItalicText>Connect via </ItalicText>
              </p>
              <li>
                <ExternalYellow linkTo="https://www.instagram.com/_carina_martins_/">
                  Instagram
                </ExternalYellow>
              </li>
              {/*
              <li>
                Vimeo{' '}
                <ExternalYellow linkTo="https://vimeo.com/carinamrts/">
                  &#64;carinamrts
                </ExternalYellow>
              </li>
              <li>
                Facebook{' '}
                <ExternalYellow linkTo="https://www.facebook.com/CarinaMrts/">
                  &#64;CarinaMrts
                </ExternalYellow>
              </li>
              <li>
                Twitter{' '}
                <ExternalYellow linkTo="https://twitter.com/_carinamartins_">
                  &#64;_carinamartins_
                </ExternalYellow>
              </li>
              */}
            </ul>
          </ContactNav>
        </ContactList>
        <ContactImage>
          <Img
            loading="eager"
            className="mainMenuImageContact"
            fluid={image.file.childImageSharp.fluid}
            alt="Carina Martins - contact - forest at Sintra"
          />
        </ContactImage>
      </ContactContainer>
    </Layout>
  )
}

export default ContactPage
